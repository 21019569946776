import React from "react";
import { Home, Unauthorised, Pagenotfound } from "./Pages";
import Layout from "./components/Layout";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import axios from "./api/axios";

export const ROLES = {
  User: 10,
  Admin: 10000,
  Editor: 5000,
};

const App = () => {
  const backendconnect = async () => {
    try {
      const response = await axios.get("/connect", {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      console.log(response.data);
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response");
        console.log(err);
      } else {
        console.log("Registration Failed");
      }
    }
  };

  backendconnect();

  return (
    <>
      <Routes>
        <Route className="Layout" path="/" element={<Layout />}>
          <Route path="/unauthorised" element={<Unauthorised />} />
          <Route path="/pagenotfound" element={<Pagenotfound />} />
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Pagenotfound />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
