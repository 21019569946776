import { React, useState, useEffect, useRef } from "react";
import "./Home.css";
import inditechlogo from "../../assets/Core/logo.png";
import { QRCodeCanvas } from "qrcode.react";
import miragelogo from "../../assets/Core/2.png";

const Home = () => {
  const [inputText, setInputText] = useState(""); // For link input
  const [wifiDetails, setWifiDetails] = useState({
    ssid: "",
    password: "",
    encryption: "WPA",
  }); // For WiFi details
  const [mode, setMode] = useState("link"); // 'link' or 'wifi'
  const [addLogo, setAddLogo] = useState(false); // Whether to add a logo to QR
  const [logo, setLogo] = useState(null); // To store the uploaded logo
  const qrCodeRef = useRef(); // Ref for accessing the QRCodeCanvas

  useEffect(() => {
    document.title = "QR Code Generator | Inditech.in";
  }, []);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleWifiChange = (e) => {
    const { name, value } = e.target;
    setWifiDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const generateWifiQR = () => {
    const { ssid, password, encryption } = wifiDetails;
    return `WIFI:T:${encryption};S:${ssid};P:${password};;`;
  };

  const handleLogoToggle = () => {
    setAddLogo(!addLogo);
  };
  const fallbackQR = "https://openqr.inditech.in";

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        alert("Please upload a valid image file.");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result); // Set the uploaded image as base64
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDownload = () => {
    // Ensure that the canvas element is present
    const canvas = qrCodeRef.current?.querySelector("canvas");
    if (!canvas) {
      console.error("Canvas element not found in QRCodeCanvas");
      return;
    }

    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const link = document.createElement("a");
    link.href = pngUrl;
    link.download = "qr-code.png";
    link.click();
  };

  const renderQRCodeWithLogo = () => {
    return (
      <div ref={qrCodeRef}>
        <QRCodeCanvas
          value={
            mode === "link"
              ? inputText !== ""
                ? inputText
                : "https://openqr.inditech.in"
              : generateWifiQR()
          }
          size={256}
          renderAs="canvas"
          level="H"
          includeMargin={true}
          imageSettings={
            addLogo && logo
              ? {
                  src: logo,
                  height: 75,
                  width: 75,
                  excavate: true,
                }
              : null
          }
        />
      </div>
    );
  };

  return (
    <div className="home-main">
      <div className="home-core">
        <div className="home-hero">
          <div className="home-hero-container">
            <img
              className="home-hero-logo"
              src={miragelogo}
              alt="Inditech Logo"
            />
            {/* <h1 className="home-hero-text">OpenQr</h1> */}
          </div>
          <div className="home-hero-text-container">
            <h1 className="home-hero-text">Generate QR Codes in Real-Time!</h1>
            <p className="home-hero-description">
              Create QR codes for links or WiFi logins instantly.
            </p>
          </div>
        </div>
        <div className="home-container">
          <div className="home-main-container">
            <div className="home-mode-toggle">
              <button
                className={`home-mode-button ${
                  mode === "link" ? "active" : ""
                }`}
                onClick={() => setMode("link")}
              >
                Link QR
              </button>
              <button
                className={`home-mode-button ${
                  mode === "wifi" ? "active" : ""
                }`}
                onClick={() => setMode("wifi")}
              >
                WiFi QR
              </button>
            </div>

            <div className="home-qrcode-container">
              {mode === "link" ? (
                <input
                  className="home-input"
                  type="text"
                  onChange={handleInputChange}
                  value={inputText}
                  placeholder="Enter URL or Text"
                />
              ) : (
                <div className="home-wifi-form">
                  <input
                    type="text"
                    className="home-input"
                    placeholder="WiFi SSID"
                    name="ssid"
                    value={wifiDetails.ssid}
                    onChange={handleWifiChange}
                  />
                  <input
                    type="password"
                    className="home-input"
                    placeholder="WiFi Password"
                    name="password"
                    value={wifiDetails.password}
                    onChange={handleWifiChange}
                  />
                  <select
                    className="home-input"
                    name="encryption"
                    value={wifiDetails.encryption}
                    onChange={handleWifiChange}
                  >
                    <option value="WPA">WPA/WPA2</option>
                    <option value="WEP">WEP</option>
                    <option value="">None</option>
                  </select>
                </div>
              )}

              <div className="home-qrcode-display">
                {renderQRCodeWithLogo()}
              </div>
            </div>

            <div className="home-bottom-container">
              <div className="home-bottom-button-container">
                <button
                  className="home-cssbuttons-io-button"
                  onClick={handleDownload}
                >
                  Download QR
                  <div className="home-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path
                        fill="currentColor"
                        d="M5 20h14a1 1 0 0 0 .993-.883L20 19v-8h-2v8H6v-8H4v8a1 1 0 0 0 .883.993L5 20zm6-10V4h2v6h3.5L12 14l-4.5-4H11z"
                      ></path>
                    </svg>
                  </div>
                </button>
                <button
                  className="home-cssbuttons-io-button"
                  onClick={handleLogoToggle}
                >
                  {addLogo ? "Remove Logo" : "Add Logo"}
                </button>
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={handleLogoUpload}
                className="home-file-input"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
